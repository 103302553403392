.radio_flex {
  display: flex;
  flex-wrap: wrap;
}
.radio_flex label.vkuiRadio {
  width: auto;
  margin-left: 0;
  margin-right: 16px;
  padding: 0;
}

.radio_flex label.vkuiCheckbox {
  width: 46%;
  margin-left: 0;
  margin-right: 16px;
  padding: 0;
}

@media (max-width: 508px) {
  .radio_flex label.vkuiCheckbox {
    width: 100%;
  }
}

.form_test .vkuiFormItem__top {
  white-space: unset;
}
.form_test .vkuiFormItem__top span {
  font-size: 1.4em;
}
.img_test {
  width: 100%;
  margin-top: 10px;
}
.vkuiBanner__in .vkuiTextarea__el {
  background-color: var(--background_suggestions);
}
.tren_av {
  width: 80px;
  height: 115px;
  border-radius: 20px;
  align-self: center;
}
.tren_wrap .tren_av {
  margin-right: 16px;
}
.tren_wrap .vkuiRichCell__text {
  white-space: normal;
}
.tren_wrap .vkuiRichCell__caption {
  white-space: normal;
}
.tren_wrap .discont {
  text-decoration: line-through;
}
.tren_disable {
}
.main_tren_wrap .vkuiSimpleCell {
  white-space: normal;
  flex-flow: wrap;
}
.main_tren_wrap .vkuiSimpleCell .val {
  font-size: 20px;
  font-weight: bold;
}
.main_tren_wrap .vkuiProgress {
  height: 8px;
  border-radius: 8px;
}
.main_tren_wrap .vkuiProgress__in {
  transition: width 0.5s ease;
}
.main_tren_wrap .btn_les_wrap h3 {
  width: 100%;
  margin-right: 16px;
}
.main_tren_wrap .days_wrap .vkuiSimpleCell__after {
  padding-bottom: 8px;
}
@media (max-width: 508px) {
  .main_tren_wrap .btn_les_wrap .vkuiSimpleCell__after {
    width: 100%;
  }
  .main_tren_wrap .btn_les_wrap .vkuiSimpleCell__after .vkuiButton {
    margin-right: 16px;
  }
}
.btn_dz_link {
  margin-top: 32px;
  width: 100%;
  padding: 0 32px;
}

.btn_chat_link {
  width: 100%;
  padding: 0 32px;
}
.flex-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 16px 0 0;
}
#help1 {
  align-items: center;
}
.all_les_wrap {
  flex-direction: column;
}
.all_les_btn {
  margin: 5px 0;
}
.month_wrap button {
  margin: 0 5px;
}
.month_wrap ~ button {
  margin-top: 16px;
}
